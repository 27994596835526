import { render, staticRenderFns } from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=template&id=204aedf4&scoped=true&lang=html"
import script from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=script&lang=javascript"
export * from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=script&lang=javascript"
import style0 from "./CommonNamesAndAbbreviationsWrapper.vue?vue&type=style&index=0&id=204aedf4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../modules/rajalex/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204aedf4",
  null
  
)

export default component.exports