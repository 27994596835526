import { render, staticRenderFns } from "./KeywordListWrapper.vue?vue&type=template&id=4f8f8ff5&lang=html"
import script from "./KeywordListWrapper.vue?vue&type=script&lang=js"
export * from "./KeywordListWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../modules/rajalex/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports