import Vue from "vue"
import VueGtm from "@gtm-support/vue2-gtm"
import VueRouter from "vue-router"
import DesignSystem from "edita-ds/dist/rajalex/rajalex.js"
import "edita-ds/dist/rajalex/styles.css"

import App from "@/App.vue"
import i18n from "@/i18n"
import router from "@/router"
import store from "@/store"

Vue.config.productionTip = false

// Inject global dependencies to Vue
Vue.use(VueRouter) // Have to inject here, otherwise it does not work
Vue.use(DesignSystem)

if (process.env.VUE_APP_GOOGLE_TAG_MANAGER) {
  Vue.use(VueGtm, {
    // NOTE: See documentation here: https://github.com/mib200/vue-gtm
    id: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
    // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    //   "gtm_auth": "AB7cDEf3GHIjkl-MnOP8qr",
    // },
    debug: process.env.NODE_ENV === "development",
    loadScript: true,
    vueRouter: router,
    // ignoredViews: ["homepage"],
  })
}

// Directory for clicking outside element
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent)
  },
})

store.setRouter(router)

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount("#app")
