<template>
  <div id="app">
    <PageContainer>
      <PageHeader
        v-if="showHeader"
        :active-params="navCurrentRouteParams"
        :active="navCurrentRoute"
        :advanced-search-route="advancedSearchRoute"
        :application-languages="applicationLanguages"
        :logo="serviceName"
        :nav-items="extraNav"
        :options-user-zone="hasUserZone ? { loggedIn, displayName: userDisplayName } : null"
        :suggestion-items="suggestionItems"
        :ui-language="uiLanguage"
        @clear-suggest="clearSuggest"
        @get-suggest="getSuggest"
        @logout="signOut"
        @search-global="searchGlobal"
      />
      <BaseNav
        v-if="showNav"
        :active="navCurrentRoute"
        :anchor="$route.hash"
        :items="navItems"
        :ui-language="uiLanguage"
        variant="global"
        orientation="horizontal"
      />
      <router-view
        @login="authenticate"
      />
      <PageFooter
        v-if="showFooter"
        :options-page-footer-branding="optionsPageFooter.PageFooterBranding"
        :options-page-footer-links="optionsPageFooter.PageFooterLinks"
        :options-page-footer-other-services="optionsPageFooter.PageFooterOtherServices"
        :ui-language="uiLanguage"
      />
    </PageContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import storeTypes from "@common/config/store-types"
import AppBase from "@wrappers/mixins/AppBase.vue"
import AppSearchSuggest from "@wrappers/mixins/AppSearchSuggest.vue"
import AppSubjects from "@wrappers/mixins/AppSubjects.vue"

export default {
  name: "App",
  mixins: [
    AppBase,
    AppSearchSuggest,
    AppSubjects,
  ],
  computed: {
    ...mapState("auth", {
      loggedIn: "loggedIn",
      userDisplayName: "userDisplayName",
      userType: "userType",
    }),
  },
  methods: {
    ...mapActions("auth", {
      authenticate: storeTypes.EDILEX_AUTHENTICATE,
      signOut: storeTypes.EDILEX_SIGNOUT,
    }),
  },
}
</script>

<style lang="scss" scoped>
  // No font imports needed in Rajalex

  // Import tokens and style for #app
  @import "edita-ds/dist/rajalex/tokens/tokens";
  @import "edita-ds/src/styles/common";
  @import "edita-ds/src/styles/print";
</style>
